<template>
  <div id="root">
    <div>
      <el-button size="small" type="primary" @click="showEdit=true,editData={state:'1'}">添加域名</el-button>
    </div>

    <el-table size="mini" border style="margin-top: 20px;" height="100%" :data="list">
      <el-table-column type="index" width="80px" label="序号"/>
      <el-table-column label="名称" width="150px" prop="name"/>
      <el-table-column label="域名地址" prop="url"/>
      <el-table-column label="状态" width="120px" prop="stateStr"/>
      <el-table-column label="创建时间" width="200px">
        <template slot-scope="scope">{{getTimeStr(scope.row.crateTime)}}</template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button size="small" type="warning" @click="beginEdit(scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click="beginDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>



    <el-dialog :visible.sync="showEdit" width="400px" :title="showEdit.id?'编辑':'新增'" :close-on-click-modal="false">
      <el-row :gutter="20" type="flex" align="middle">
        <el-col :span="6">名称</el-col>
        <el-col :span="18">
          <el-input size="small" placeholder="请输入名称，如 电信千兆" v-model="editData.name"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" align="middle"  v-model="editData.url" style="margin-top: 20px;">
        <el-col :span="6">域名地址</el-col>
        <el-col :span="18">
          <el-input size="small" placeholder="请输入域名，如 https://www.baidu.com:80" v-model="editData.url"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" align="middle" style="margin-top: 20px;">
        <el-col :span="6">状态</el-col>
        <el-col :span="18">
          <el-select size="small" style="width: 100%;" v-model="editData.state">
            <el-option label="启用" value="1"></el-option>
            <el-option label="停用" value="0"></el-option>
            <el-option label="异常" value="2"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="showEdit=false">取消</el-button>
        <el-button type="primary" size="small" @click="confirmAdd">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "hostManager",
  data() {
    return {
      editData:{
        state:"1"
      },
      showEdit:false,
      list: []
    }
  },
  created() {
    this.fillData()
  },
  methods:{
    beginEdit(row){
      this.editData=JSON.parse(JSON.stringify(row));
      this.showEdit=true;
    },
    async beginDel(row){
      await this.$confirm("删除后，将导致部分二维码失效。是否确认删除？")
      const res= await this.$http.post("/host/del",[row.id]);
      if (!res){
        this.$message({
          type:"error",
          message:"删除失败"
        })
        return;
      }
      await this.fillData()
    },
    getTimeStr(timestamp){
      let date = new Date(timestamp);
      return `${date.getFullYear()}年${date.getMonth()+1}月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    },
    async confirmAdd(){
      const res= await axios.post(process.env.VUE_APP_BASE_API + (this.editData.id!=null?'host/update':"host/add"), this.editData)
      if (res.data.code!=200){
        this.$message({
          type:"error",
          message:res.data.message
        })
        return;
      }
      if (!res.data.data){
        this.$message({
          type:"error",
          message:"操作失败"
        })
        return;
      }
      this.showEdit=false;
      this.fillData();
    },
    async fillData(){
      const res= await axios.post(process.env.VUE_APP_BASE_API + "host/list")
      if (res.data.code!=200){
        this.$alert(res.data.message)
        return;
      }
      this.list=res.data.data;
    }
  }
}
</script>

<style scoped>
#root {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>